h1, h2, h3 {
  margin: 0 0 30px;
  padding: 0;
  font-weight: 700; }

body {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  background: white;
  color: #6e7c8c;
  line-height: 1.53; }

h2 {
  font-size: 32px;
  text-align: center; }

h3 {
  font-size: 24px; }

a {
  text-decoration: underline;
  color: #6e7c8c; }
  a:visited {
    color: #6e7c8c; }
  a:hover {
    text-decoration: none; }

p {
  margin: 0 0 15px; }

img {
  max-width: 100%;
  height: auto; }

.section {
  width: 100%;
  padding: 60px 0 70px; }

.section--dark {
  background: #f2f6fb; }

footer {
  padding: 30px 0 23px;
  text-align: center;
  background: #6e7c8c;
  font-size: 16px;
  color: white; }

header {
  padding: 0; }

.container {
  width: 100%;
  max-width: 880px;
  margin: 0 auto;
  padding: 0 20px;
  margin: 0 auto;
  box-sizing: border-box; }

.container--menu {
  max-width: 1040px;
  text-align: center; }

.about, .service, .contact {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-flow: column; }
  @media (min-width: 48em) {
    .about, .service, .contact {
      justify-content: space-between;
      flex-flow: row; } }

.menu {
  background: #f2f6fb;
  line-height: 2;
  transition: box-shadow 0.2s;
  padding: 20px 0 10px; }
  .menu .container {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center; }
    @media (min-width: 64.375em) {
      .menu .container {
        flex-flow: row; } }
  .menu ul {
    flex: 1 1 auto;
    position: relative;
    top: 3px;
    list-style: none;
    padding: 0;
    margin: 0;
    font-weight: 700; }
    .menu ul li {
      display: inline-block;
      margin: 0 15px; }
  .menu a {
    text-decoration: none; }
    .menu a:hover, .menu a:active, .menu a:focus {
      color: #a4b3c5; }
    .menu a.fb {
      color: #3b5998; }

@media (min-width: 64.375em) {
  .menu__logo {
    flex: 0 1 199px; } }

.menu--sticky {
  width: 100%;
  position: fixed;
  top: 0;
  box-shadow: 0px 1px 10px #999;
  z-index: 10;
  padding-top: 5px; }
  .menu--sticky + .slider {
    margin-top: 92.5px; }
  .menu--sticky .menu__logo {
    display: none; }
  @media (min-width: 48em) {
    .menu--sticky {
      padding-top: 20px; }
      .menu--sticky .menu__logo {
        display: block; } }

.icon {
  display: inline-block;
  margin-right: 6px; }

.icon--fb {
  position: relative;
  top: 4px;
  width: 20px;
  height: 20px;
  background: url("../images/facebook.png"); }

.icon--phone {
  position: relative;
  top: 7px;
  width: 25px;
  height: 25px;
  background: url("../images/phone.png"); }

.icon--mail {
  position: relative;
  top: 4px;
  width: 25px;
  height: 17px;
  background: url("../images/mail.png"); }

.icon--address {
  position: relative;
  top: 4px;
  width: 25px;
  height: 25px;
  background: url("../images/address.png"); }

h1, h2, h3 {
  margin: 0 0 30px;
  padding: 0;
  font-weight: 700; }

body {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  background: white;
  color: #6e7c8c;
  line-height: 1.53; }

h2 {
  font-size: 32px;
  text-align: center; }

h3 {
  font-size: 24px; }

a {
  text-decoration: underline;
  color: #6e7c8c; }
  a:visited {
    color: #6e7c8c; }
  a:hover {
    text-decoration: none; }

p {
  margin: 0 0 15px; }

img {
  max-width: 100%;
  height: auto; }

.about__img {
  flex: 0 0 45%;
  order: 2; }
  @media (min-width: 48em) {
    .about__img {
      order: 1; } }
  .about__img img {
    border: 1px solid #6e7c8c; }

.about__text {
  flex: 0 1 50%;
  order: 1;
  margin-bottom: 15px; }
  @media (min-width: 48em) {
    .about__text {
      order: 2; } }

.service {
  margin-bottom: 30px; }
  @media (min-width: 48em) {
    .service {
      margin-bottom: 60px; } }

.service__img {
  flex: 0 1 50%;
  text-align: center;
  display: none; }
  @media (min-width: 48em) {
    .service__img {
      display: block; } }
  .service__img img {
    display: inline-block; }

.service__text {
  flex: 0 1 50%; }

.contact__map {
  height: 250px;
  width: 100%;
  order: 2; }
  @media (min-width: 48em) {
    .contact__map {
      flex: 0 1 47%;
      order: 1; } }
  .contact__map img {
    max-width: none !important;
    height: auto; }

.contact__text {
  font-style: normal;
  order: 1; }
  @media (min-width: 48em) {
    .contact__text {
      flex: 0 0 47%;
      order: 2; } }
  .contact__text > div {
    margin-bottom: 20px; }

.contact__title {
  margin-bottom: 14px;
  font-weight: 700;
  font-size: 26px; }

h1, h2, h3 {
  margin: 0 0 30px;
  padding: 0;
  font-weight: 700; }

body {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  background: white;
  color: #6e7c8c;
  line-height: 1.53; }

h2 {
  font-size: 32px;
  text-align: center; }

h3 {
  font-size: 24px; }

a {
  text-decoration: underline;
  color: #6e7c8c; }
  a:visited {
    color: #6e7c8c; }
  a:hover {
    text-decoration: none; }

p {
  margin: 0 0 15px; }

img {
  max-width: 100%;
  height: auto; }

.form {
  max-width: 581px;
  margin: 0 auto;
  text-align: center; }
  .form input, .form textarea {
    width: 92%;
    padding: 13px 10px;
    border: 1px solid #c4cfdb;
    border-radius: 4px;
    background: white;
    font-size: 15px;
    color: #6e7c8c;
    line-height: 1.2; }
  .form button {
    padding: 12px 11px;
    border: 0;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    background-color: #6e7c8c;
    color: white;
    border-radius: 4px;
    transition: background-color 0.2s; }
    .form button:hover, .form button:active, .form button:focus {
      background-color: #a4b3c5; }

.form__row {
  margin-bottom: 29px; }

@media (min-width: 28.125em) {
  .form__row--inline {
    width: 49%;
    display: inline-block; }
    .form__row--inline input {
      width: 85%; } }

.filters {
  text-align: center;
  margin-bottom: 50px; }

.filters__filter {
  display: block; }
  @media (min-width: 28.125em) {
    .filters__filter {
      display: inline-block; }
      .filters__filter:after {
        display: inline-block;
        text-decoration: none;
        content: " / ";
        white-space: pre; } }

.gallery {
  display: flex;
  flex-wrap: wrap; }

.gallery__column {
  flex: 100%;
  max-width: 100%;
  padding: 0 4px; }
  @media (min-width: 28.125em) {
    .gallery__column {
      flex: 48%;
      max-width: 48%; } }
  @media (min-width: 48em) {
    .gallery__column {
      flex: 30%;
      max-width: 30%; } }
  .gallery__column a {
    margin-top: 8px;
    vertical-align: middle;
    transform: scale(0);
    transition: all 350ms ease; }

.scale-anm {
  transform: scale(1); }

.slider {
  display: flex;
  align-items: end;
  position: relative;
  height: 50vh;
  max-width: 1920px;
  margin: 0 auto; }

.slider__slide {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center; }

.slider__title {
  position: relative;
  top: 11vh;
  font-size: 32px;
  text-align: center;
  line-height: 1.4; }
  @media (min-width: 48em) {
    .slider__title {
      left: -10px;
      text-align: left;
      line-height: 1.2;
      font-size: 48px; } }
  @media (min-width: 64.375em) {
    .slider__title {
      left: -70px;
      font-size: 56px; }
      .slider__title:before {
        position: absolute;
        top: -20px;
        left: -110px;
        width: 102px;
        height: 102px;
        border-radius: 50%;
        padding-top: 15px;
        background: #f8fcfe;
        color: #a4b3c5;
        box-sizing: border-box;
        text-align: center; } }

.slider__subtitle {
  font-weight: normal;
  font-size: 46px; }

.slider__slide--1 {
  background-image: url("../images/strom.png"); }
  @media (min-width: 64.375em) {
    .slider__slide--1 .slider__title:before {
      content: "1."; } }

.slider__slide--2 {
  background-image: url("../images/dum.png"); }
  @media (min-width: 64.375em) {
    .slider__slide--2 .slider__title:before {
      content: "2."; } }

.slider__slide--3 {
  background-image: url("../images/hala.png"); }
  @media (min-width: 64.375em) {
    .slider__slide--3 .slider__title:before {
      content: "3."; } }

.slider__slide--active {
  display: block; }

.slider__controls {
  position: absolute;
  top: 20vh;
  cursor: pointer; }
